<!--
 * @Descripttion: 个人中心
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-09-15 18:58:56
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-12-16 17:59:46
-->

<template>
  <div class="content">
    <!-- 头部信息 str -->
    <div class="header">
      <div class="header-top">
        <div class="header-top-left">Hi,工号 {{ user.empno }}</div>
        <div class="header-top-right">
          <img
            src="@/assets/newskin/geseting.png"
            alt=""
            @click="$router.push({ path: '/set' })"
          />
          <div
            class="header-top-right-mes"
            @click="$router.push({ path: '/drnews' })"
          >
            <img src="@/assets/newskin/gemsg.png" alt="" />
            <div class="header-top-right-mes-remind" v-if="showredmsg">
              <img src="@/assets/newskin/geredmsg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="header-con">
        <div class="header-con-left">
          <div class="header-con-left-logo">
            <img :src="user.headimg" alt="" />
          </div>
        </div>
        <div class="header-con-cen">
          <div class="header-con-cen-top">
            <div class="header-con-cen-top-left">
              {{ user.empname }}
            </div>
            <div
              class="header-con-cen-top-right"
              @click="$router.push({ path: '/myScore' })"
            >
              <p>
                我的积分：{{ JFNUM }}分
                <img src="@/assets/newskin/you.png" alt="" />
              </p>
            </div>
          </div>
          <div class="header-con-cen-bom">
            {{ jgname }}
          </div>
        </div>
        <div class="header-con-right">
          <img src="@/assets/newskin/gerenzhongxin.png" alt="" />
        </div>
      </div>
      <div class="header-bom">
        <div class="header-bom-cen">
          <p class="serialnumber">职业编号</p>
          <p class="controlno">{{ user.certno ? user.certno : "暂未持证" }}</p>
        </div>
      </div>
    </div>
    <!-- 头部信息 end -->
    <!-- 指标信息 str -->
    <div class="target">
      <div class="target-con">
        <p class="target-con-title">个人年度新保</p>
        <p class="target-con-value">{{ form.xinb }}</p>
      </div>
      <div class="target-con">
        <p class="target-con-title">个人年度续保</p>
        <p class="target-con-value">{{ form.xub }}</p>
      </div>
      <div class="target-con">
        <p class="target-con-title">团队总人力</p>
        <p class="target-con-value">{{ form.tdsum }}</p>
      </div>
      <div class="target-con">
        <p class="target-con-title">出单人力</p>
        <p class="target-con-value">{{ form.tdnum }}</p>
      </div>
    </div>
    <!-- 指标信息 end -->
    <!-- 模块信息 str -->
    <div class="module">
      <div class="module-con">
        <div class="module-con-cen" @click="$router.push({ path: '/myTeam' })">
          <div class="module-con-cen-top">
            <img src="@/assets/newskin/modulegeren.png" alt="" />
          </div>
          <div class="module-con-cen-bom">
            <p class="module-con-cen-bom-title">我的团队</p>
            <p class="module-con-cen-bom-value">团队管理</p>
          </div>
        </div>
        <div
          class="module-con-cen"
          @click="$router.push({ path: '/businesstrack' })"
        >
          <div class="module-con-cen-top">
            <img src="@/assets/newskin/modulezhuizong.png" alt="" />
          </div>
          <div class="module-con-cen-bom">
            <p class="module-con-cen-bom-title">业务追踪</p>
            <p class="module-con-cen-bom-value">业务情况</p>
          </div>
        </div>
        <div
          class="module-con-cen"
          @click="$router.push({ path: '/bankCard' })"
        >
          <div class="module-con-cen-top">
            <img src="@/assets/newskin/moduleyinhang.png" alt="" />
          </div>
          <div class="module-con-cen-bom">
            <p class="module-con-cen-bom-title">银行卡</p>
            <p class="module-con-cen-bom-value">我的银行卡</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 模块信息 end -->
    <!-- 指标信息 str -->
    <div class="norm">
      <div class="norm-top">
        <div class="norm-top-title">个人指标汇总</div>
        <div class="norm-top-mouth">当月</div>
      </div>
      <div
        class="norm-con"
        v-show="index < summary"
        v-for="(item, index) in zb"
        :key="index"
      >
        <div class="norm-con-cen">
          <div class="norm-con-cen-left">
            <p
              :class="
                item.zbdesc
                  ? 'norm-con-cen-left-title'
                  : 'norm-con-cen-left-titleno'
              "
            >
              {{ item.zbname }}
            </p>
            <p class="norm-con-cen-left-value">
              {{ item.zbdesc }}
            </p>
          </div>
          <div class="norm-con-cen-right">
            {{ item.soilvalue }}
          </div>
        </div>
      </div>
      <div class="norm-bom" v-if="zb.length > 5 && summary != 9999">
        <p @click="showMore">查看更多</p>
      </div>
    </div>
    <!-- 指标信息 end -->
    <!-- 指标信息 str -->
    <div class="statistics">
      <div class="statistics-borderbom">
        <div class="statistics-top">
          <div class="statistics-top-title">工作统计</div>
          <div class="statistics-top-mouth">近6月</div>
        </div>
      </div>
      <div
        class="statistics-btm"
        v-for="(item, index) in gzlist"
        :key="index"
        @click="toWages(item)"
      >
        <div class="statistics-btm-left">
          <p>{{ item.showdate }}月工资</p>
        </div>
        <div class="statistics-btm-right">
          <p>￥{{ item.value }}</p>
        </div>
        <div class="statistics-btm-img">
          <img src="@/assets/newskin/youtow.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 指标信息 end -->
    <div class="footer"></div>
    <div>
      <nhsx></nhsx>
    </div>
  </div>
</template>

<script>
import {
  wdmsg,
  getNowDayCount,
  getJf,
  getJfSum,
  statistics
} from '@/api/basic/index'
import nhsx from '@/views/basic/home/component/nhsx'
import { selectGRZXByEmpno } from '@/api/basic'
import { getStorage } from '@/lib/util'
export default {
  components: { nhsx },
  data () {
    return {
      summary: 5,
      showredmsg: false,
      user: {},
      jgname: '',
      JFNUM: 0,
      form: {},
      zb: [],
      gzlist: []
    }
  },
  created () {
    this.user = getStorage('u_s', '')
    this.jgname = this.user.jgname.replace(new RegExp(/(,)/g), ' > ')
    this.getwdmsgdata()
    this.getJfSumdata()
    this.getData()
  },
  methods: {
    getData () {
      selectGRZXByEmpno({ empno: this.user.empno }).then((res) => {
        this.form = res.data.data
        this.zb = this.form.zb
        this.gzlist = this.form.gzlist
        this.gzlist.forEach((item, index) => {
          this.$set(
            item,
            'showdate',
            item.date.substr(5, 7) - 9 > 0
              ? item.date.substr(5, 7)
              : item.date.substr(6, 7)
          )
        })
      })
    },
    getwdmsgdata () {
      // 是否有消息
      wdmsg().then((res) => {
        if (res.data.data.num > 0) {
          this.showredmsg = true
        } else {
          this.showredmsg = false
        }
      })
    },
    getJfSumdata () {
      // 获取积分
      getJfSum().then((res) => {
        if (res.data.data === null) {
          this.JFNUM = 0
        } else {
          this.JFNUM = res.data.data
        }
      })
    },
    showMore () {
      this.summary = 9999
    },
    toWages (val) {
      if (val.value - 0 > 0) {
        this.$router.push(`/wages?yearmon=${val.date}`)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.content {
  background: #fff;
}

.header {
  height: 4.52rem;
  background: url('../../../assets/newskin/bei.png');
  background-size: 100% 100%;

  .header-top {
    height: 1.19rem;
    display: flex;

    .header-top-left {
      width: 80%;
      font-size: 0.32rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      height: 1.19rem;
      line-height: 1.19rem;
      color: #FFFFFF;
      opacity: 0.8;
      padding-left: 0.47rem;
    }

    .header-top-right {
      display: flex;

      img {
        margin: 0.43rem 0.37rem 0 0;
        width: 0.57rem;
        height: 0.55rem;
        // height auto
      }

      .header-top-right-mes {
        position: relative;

        .header-top-right-mes-remind {
          position: absolute;
          width: 0.18rem;
          height: 0.18rem;
          top: 0.15rem;
          right: 0.3rem;

          img {
            margin: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .header-con {
    height: 1.18rem;
    display: flex;

    .header-con-left {
      .header-con-left-logo {
        margin-left: 0.67rem;
        width: 1.18rem;
        height: 1.18rem;
        margin-top: 0.08rem;

        img {
          width: 1.1rem;
          height: 1.1rem;
          border-radius: 50%;
          border: 0.06rem solid #fff;
        }
      }
    }

    .header-con-cen {
      width: 53%;
      margin-left: 0.36rem;

      .header-con-cen-top {
        display: flex;

        .header-con-cen-top-left {
          font-size: 0.51rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 0.5rem;
        }

        .header-con-cen-top-right {
          width: 2.47rem;
          text-align: center;
          border: 1px solid #ffffff8c;
          border-radius: 0.3rem;
          margin-left: 0.15rem;
          height: 0.43rem;
          line-height: 0.43rem;
          p {
            font-size: 0.25rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #fff;
          }

          img {
            width: 0.2rem;
            height: 0.18rem;
          }
        }
      }

      .header-con-cen-bom {
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 0.2rem;
      }
    }

    .header-con-right {
      img {
        width: 2.09rem;
        height: auto;
        margin-top: 0.17rem;
      }
    }
  }

  .header-bom {
    height: 2.15rem;
    background: url('../../../assets/newskin/beione.png') no-repeat;
    background-size: 100%;

    .header-bom-cen {
      width: 8.67rem;
      margin: 0.8rem auto 0;
      display: flex;

      .serialnumber {
        margin-left: 0.72rem;
        margin-top: 0.4rem;
        color: #FADDC2;
      }

      .controlno {
        margin-left: 0.3rem;
        margin-top: 0.4rem;
        color: #B39888;
      }
    }
  }
}

.target {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 0.3rem solid #F5F5F9;
  margin-top: 0.1rem;

  .target-con {
    width: 4.99rem;
    height: 1.89rem;

    .target-con-title {
      margin-left: 0.67rem;
      margin-top: 0.35rem;
      font-size: 0.35rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #999999;
    }

    .target-con-value {
      margin-left: 0.67rem;
      margin-top: 0.2rem;
      font-size: 0.51rem;
      font-family: DIN 1451 Mittelschrift;
      font-weight: 400;
      color: #444444;
    }
  }

  .target-con:nth-of-type(1), .target-con:nth-of-type(2) {
    border-bottom: 1px solid #F2F2F2;
  }

  .target-con:nth-of-type(2), .target-con:nth-of-type(4) {
    border-left: 1px solid #F2F2F2;
  }
}

.module {
  background: #fff;

  .module-con {
    width: 9.09rem;
    height: 2.25rem;
    margin: 0.79rem auto;
    display: flex;

    .module-con-cen {
      background: pink;
      width: 2.94rem;
      height: 2.24rem;

      .module-con-cen-top {
        img {
          margin-top: 0.27rem;
          margin-left: 0.24rem;
          width: 0.72rem;
        }
      }

      .module-con-cen-bom {
        .module-con-cen-bom-title {
          font-size: 0.37rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #212121;
          line-height: 0.54rem;
          margin-left: 0.26rem;
        }

        .module-con-cen-bom-value {
          font-size: 0.28rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 0.54rem;
          margin-left: 0.29rem;
        }
      }
    }

    .module-con-cen:nth-of-type(1) {
      background: #FFF4F4;
    }

    .module-con-cen:nth-of-type(2) {
      background: #F8FAFF;
      margin: 0 0.14rem;
    }

    .module-con-cen:nth-of-type(3) {
      background: #FFFCF8;
    }
  }
}

.norm {
  background: #fff;
  border-bottom: 0.3rem solid #f5f5f9;

  .norm-top {
    width: 9.1rem;
    height: 0.62rem;
    margin: 0 auto;
    display: flex;

    .norm-top-title {
      width: 85%;
      font-size: 0.44rem;
      font-family: FZLanTingHei-DB-GBK;
      font-weight: 600;
      color: #333333;
      line-height: 0.5rem;
    }

    .norm-top-mouth {
      width: 1.35rem;
      height: 0.52rem;
      border: 1px solid #9B9B9B;
      text-align: center;
      line-height: 0.52rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #9B9B9B;
    }
  }

  .norm-con {
    width: 9.1rem;
    margin: 0.27rem auto;
    background: #FCF3E9;

    .norm-con-cen {
      background: #fff;
      margin-left: 0.09rem;
      height: 2.22rem;
      box-shadow: 0.1rem 0.1rem 0.1rem 0rem #eee;
      display: flex;

      .norm-con-cen-left {
        width: 80%;

        .norm-con-cen-left-title {
          margin-top: 0.45rem;
          margin-left: 0.47rem;
          font-size: 0.41rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #070707;
        }

        .norm-con-cen-left-titleno {
          margin-left: 0.47rem;
          line-height: 2.22rem;
          font-size: 0.41rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #070707;
        }

        .norm-con-cen-left-value {
          margin-left: 0.47rem;
          margin-top: 0.2rem;
          font-size: 0.32rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }

      .norm-con-cen-right {
        width: 20%;
        height: 2.22rem;
        line-height: 2.22rem;
        font-size: 0.41rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #999999;
      }
    }
  }

  .norm-bom {
    p {
      width: 2.68rem;
      height: 0.8rem;
      line-height: 0.8rem;
      border-radius: 0.5rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
      border: 1px solid #E4E4E4;
      text-align: center;
      margin: 0 auto 0.27rem;
    }
  }
}

.statistics {
  .statistics-borderbom {
    height: 1.11rem;
    border-bottom: 1px solid #EFEFEF;

    .statistics-top {
      width: 9.1rem;
      height: 0.62rem;
      margin: 0.79rem auto 0.49rem;
      display: flex;

      .statistics-top-title {
        width: 85%;
        font-size: 0.44rem;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 600;
        color: #333333;
        line-height: 0.5rem;
      }

      .statistics-top-mouth {
        width: 1.35rem;
        height: 0.52rem;
        border: 1px solid #9B9B9B;
        text-align: center;
        line-height: 0.52rem;
        font-size: 0.32rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #9B9B9B;
      }
    }
  }

  .statistics-btm {
    display: flex;
    width: 9.1rem;
    height: 1.32rem;
    margin: 0 auto;
    border-bottom: 1px solid #EFEFEF;

    .statistics-btm-left {
      width: 20%;

      p {
        line-height: 1.32rem;
        font-size: 0.37rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2C2C3C;
      }
    }

    .statistics-btm-right {
      width: 75%;

      p {
        line-height: 1.32rem;
        font-size: 0.41rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #F84747;
        text-align: right;
      }
    }

    .statistics-btm-img {
      position: relative;
      width: 5%;

      img {
        position: absolute;
        width: 0.4rem;
        height: 0.4rem;
        top: 50%;
        left: 17%;
        margin-top: -0.2rem;
      }
    }
  }
}

.footer {
  height: 10vh;
}
</style>
